const colors = {
	primary_text_color: '#29354c',
	primary_text_light: '#abb0bb',
	link_color: '#1d9bf0',
	secondary_text_color: '#0f1419',
	border_line_color: '#E0E0E0',
	white_color: '#FFFFFF',
	card_shadow_color: 'rgba(0, 0, 0, 0.2)',
	light_gray: '#BDBDBD',
	dark_gray: '#767575',
	blue_background: '#292d3e',
};

export default colors;
