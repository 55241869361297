const researchAxisDescription = {
	Pharmacogenomics: {
		'research-axis': 'Pharmacogenomics',
		/* description: `Through the joint analysis of molecular and pharmacological
		profiles of cancer cells, our objective is to discover novel vulnerabilities
		in cancer and identify predictive markers of drug response. By integrating
		pre-clinical and clinical data, we strive to uncover valuable insights
		that can enhance our understanding of cancer and optimize treatment strategies.`, */

		// Chat-gpt condensed below:
		description: `Our aim is to find new weaknesses in cancer and identify markers that predict
		how cancer cells will respond to drugs. We analyze molecular and pharmacological profiles
		and combine pre-clinical and clinical data to gain valuable insights for improving cancer understanding
		and treatment strategies.`,
		imagePath: '/images/logo/dna.png',
	},

	Radiomics: {
		'research-axis': 'Radiomics',
		/* description: `Radiological imaging offers a distinctive perspective on
		tumors and their macro- and microenvironment, providing valuable
		non-invasive insights. Widely employed in clinical settings, this technology
		generates vast amounts of data that we strive to leverage for the development
		of novel computational models. Our objective is to harness this rich data
		to enhance diagnosis, treatment planning, and the prediction of therapy
		response and resistance.`, */
		description: `Radiological imaging provides unique non-invasive insights into tumors and
		their surroundings.	This widely used technology generates extensive data, which we aim to
		utilize for developing new computational models. Our goal is to leverage this data to
		improve diagnosis, treatment planning, and prediction of therapy response and resistance.`,
		imagePath: '/images/logo/tomography.png',
	},

	'Multimodal Data Integration': {
		'research-axis': 'Multimodal Data Integration',
		/* description: `As technologies become more accessible, their utilization
		in research and clinical settings opens up new avenues for enhancing
		sensitivity and accuracy through effective integration. Our primary objective
		is to advance computational	modeling approaches that harness the complementary
		and synergistic nature of existing and emerging biomedical profiling technologies.`, */
		description: `Advancements in technology enable broader use in research and clinical settings, leading to improved
		sensitivity and accuracy through effective integration. Our main goal is to advance computational modeling methods
		that leverage the complementary nature of existing and emerging biomedical profiling technologies.`,
		imagePath: '/images/logo/data-integration.png',
	},

	'Software Development': {
		'research-axis': 'Software Development',
		/* description: `Scientific software is a vital pillar of modern research.
		However, the development of robust software packages, databases, and web applications
		requires extensive expertise in data and software engineering, which is often scarce
		within research labs. Our laboratory boasts a team of skilled software developers
		who collaborate closely with academic and industry partners to implement high-quality
		open-source scientific software, making it freely available to the public.`, */
		description: `Our lab overcomes the limited expertise in data and software engineering
		within research labs by employing skilled software developers. We collaborate with
		academic and industry partners to create high-quality open-source scientific software,
		ensuring its accessibility to the public.`,
		imagePath: '/images/logo/backend.png',
	},

	'Open Science': {
		'research-axis': 'Open Science',
		/* description: `We embrace the Open science principles through a collaborative and
		transparent approach to scientific research that emphasizes the free and unrestricted
		access to scientific knowledge, data, methodologies, and research outputs. We
		recognize that achieving full transparency, reproducibility, and reusability in
		research poses significant technological challenges. Therefore, our team is actively
		leveraging cutting-edge computational technologies to facilitate the adoption of an
		Open Science approach in both academic and industry labs. By providing accessible
		tools and solutions, we strive to empower researchers to embrace Open Science principles
		and advance scientific progress.`, */
		description: `We promote Open Science through collaboration, transparency, and free
		access to knowledge, data, and methodologies. To overcome challenges in transparency
		and reproducibility, we leverage advanced computational technologies. Our aim is to
		empower researchers in both academic and industry labs with accessible tools and
		solutions, fostering scientific progress through Open Science.`,
		imagePath: '/images/logo/book.png',
	},
};

export default researchAxisDescription;
