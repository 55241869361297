import React from 'react';
import Routes from './routes/Routes';
import GlobalStyles from './styles/GlobalStyles';

function App() {
	return (
		<>
			<GlobalStyles />
			<Routes />
		</>
	);
}

export default App;
